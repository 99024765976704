* {
  margin: 0;
  padding: 0;
}

.home__hero-section1 {
  color: #fff;
  padding: 160px 0;
}

.home__hero-row1 {
  align-items: center;
}

.row1 {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: row  ;
  /* flex-flow: row-reverse; */
  align-content: stretch;
}

.col1 {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper1 {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  margin-left: 15%;
}

.top-line1 {
  color: #791207;
  font-size: 40px;
  line-height: 45px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: center;
}

.heading2 {
  /* color: #fff; */
  font-size: 20px;
  line-height: 15px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: center;
}

.heading1 {
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
  text-align: center;
}

.dark {
  color: #00642f;
}

.newBg{
  background-color: #ffeb98;
}

.darkBg {
  background-color: #00642f;
}

.home__hero-subtitle1 {
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  transform :translateX(15%);
}

.home__hero-img-wrapper1 {
  max-width: 555px;
}

.home__hero-img1 {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
  width: 110%;
}



@media  only screen and (min-device-width: 0px) and (max-device-width: 320px) and (max-device-height: 790px) {
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    text-align: justify;
    font-size: 14px;
    transform: translate(-23%,-5%);
    width: 205%;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-18%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -90%);
  }

  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translate(-17%,-5%);
    width: 230px;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-18%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -90%);
  }

  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translate(-17%,-5%);
    width: 230px;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-15%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -70%);
  }

  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translateX(-18%);
    width: 178%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-25%);
  }
  .home__hero-img1{
    width: 130%;
    transform: translate(-16%, -95%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 801px) and (max-device-width: 360px) and (max-device-height: 840px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translateX(-18%);
    width: 178%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-18%);
  }
  .home__hero-img1{
    width: 130%;
    transform: translate(-18%, -95%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translateX(-18%);
    width: 178%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translateX(-18%);
    width: 178%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translateX(-18%);
    width: 178%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:800px) and (max-device-width: 385px) and (max-device-height: 999px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translateX(-18%);
    width: 178%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 385px) and (max-device-width: 390px) and (max-device-height: 722px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translateX(-18%);
    width: 178%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translateX(-18%);
    width: 178%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1{
    transform: translateX(-18%);
    width: 178%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-25%);
  }
  .home__hero-img1{
    width: 130%;
    transform: translate(-16%, -110%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1{
    font-size: 35px;
  }
  .home__hero-subtitle1 {
    transform: translateX(-22%);
    width: 186%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .heading1{
    font-size: 25px;
  }
  .home__hero-subtitle1 {
    transform: translateX(-22%);
    width: 186%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .heading1{
    font-size: 25px;
  }
  .home__hero-subtitle1 {
    transform: translateX(-22%);
    width: 186%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .heading1{
    font-size: 25px;
  }
  .home__hero-subtitle1 {
    transform: translateX(-22%);
    width: 186%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .heading1{
    font-size: 25px;
  }
  .home__hero-subtitle1 {
    transform: translateX(-22%);
    width: 186%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .heading1{
    font-size: 25px;
  }
  .home__hero-subtitle1 {
    transform: translateX(-22%);
    width: 186%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-10%,-5%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-22%, -30%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .heading1{
    font-size: 25px;
  }
  .home__hero-subtitle1{
    transform: translate(-24%,-5%);
    width: 275px;
    text-align: justify;
    font-size: 15px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-15%);
  }
  .home__hero-img1{
    width: 155%;
    transform: translate(-22%, -50%);
  }
  .bts{
    transform: translateX(0%);
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .heading1{
    font-size: 25px;
  }
  .home__hero-subtitle1{
    transform: translate(-24%,-5%);
    width: 204%;
    font-size: 15px;
    text-align: justify;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-15%);
  }
  .home__hero-img1{
    width: 155%;
    transform: translate(-22%, -50%);
  }
  .bts{
    transform: translateX(0%);
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 35px;
  }
  .heading1{
    font-size: 25px;
  }
  .home__hero-subtitle1{
    transform: translate(-15%,-7%);
    width: 151%;
    text-align: justify;
    font-size: 19px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-25%);
  }
  .home__hero-img1{
    width: 155%;
    transform: translate(-20%, -40%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 45px;
  }
  .heading1{
    font-size: 30px;
  }
  .home__hero-subtitle1{
    transform: translate(-16%,-7%);
    width: 150%;
    text-align: justify;
    font-size: 20px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-20%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-18%, -35%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 45px;
  }
  .heading1{
    font-size: 30px;
  }
  .home__hero-subtitle1{
    transform: translate(-16%,-7%);
    width: 150%;
    text-align: justify;
    font-size: 20px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-20%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-18%, -35%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 45px;
  }
  .heading1{
    font-size: 30px;
  }
  .home__hero-subtitle1{
    transform: translate(-16%,-7%);
    width: 150%;
    text-align: justify;
    font-size: 20px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-20%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-18%, -35%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 45px;
  }
  .heading1{
    font-size: 30px;
  }
  .home__hero-subtitle1{
    transform: translate(-16%,-7%);
    width: 150%;
    text-align: justify;
    font-size: 20px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-20%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-18%, -35%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .top-line1,.heading2{
    font-size: 45px;
  }
  .heading1{
    font-size: 30px;
  }
  .home__hero-subtitle1{
    transform: translate(-16%,-7%);
    width: 150%;
    text-align: justify;
    font-size: 20px;
  }
  .home__hero-text-wrapper1{
    transform: translate(-8%,-20%);
  }
  .home__hero-img1{
    width: 150%;
    transform: translate(-18%, -30%);
  }
  
  .bts{
    transform: translateX(0%);
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .row1{
    flex-flow: row;
  }
  .top-line1{
    font-size: 55px;
    line-height: 62px;
  }
  .heading2{
    font-size: 55px;
  }
  .heading1{
    font-size: 30px;
    transform: translate(0%,25%);
  }
  .home__hero-subtitle1{
    font-size: 20px;
    transform: translatex(5%);
    text-align: justify;
    width: 104%;
  }
  .home__hero-text-wrapper1{
    transform: translateX(-12%);
  }
  .home__hero-img1{
    transform: translateX(0%);
    width: 90%;
  }
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
  .row1{
    flex-flow: row;
  }
  .top-line1{
    font-size: 55px;
    line-height: 62px;
  }
  .heading2{
    font-size: 55px;
  }
  .heading1{
    font-size: 30px;
    transform: translate(0%,25%);
  }
  .home__hero-subtitle1{
    font-size: 20px;
    transform: translatex(5%);
  }
  .home__hero-text-wrapper1{
    transform: translateX(-12%);
  }
  .home__hero-img1{
    transform: translateX(-10%);
  }
  
}

/*desktops*/
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 1024px){
  .row1{
    flex-flow: row;
  }
  .top-line1{
    font-size: 55px;
    line-height: 62px;
  }
  .heading2{
    font-size: 55px;
  }
  .heading1{
    font-size: 30px;
    transform: translate(0%,25%);
  }
  .home__hero-subtitle1{
    font-size: 20px;
    transform: translatex(5%);
    text-align: justify;
    width: 93%;
  }
  .home__hero-text-wrapper1{
    transform: translateX(-5%);
  }
  .home__hero-img1{
    width: 100%;
    transform: translate(-0%,0%);
  }
  .bts{
    transform: translateX(-8%);
  }
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
  .row1{
    flex-flow: row;
  }
  .top-line1{
    font-size: 55px;
    line-height: 62px;
  }
  .heading2{
    font-size: 55px;
  }
  .heading1{
    font-size: 30px;
    transform: translate(0%,25%);
  }
  .home__hero-subtitle1{
    font-size: 20px;
    transform: translatex(5%);
    text-align: justify;
  }
  .home__hero-text-wrapper1{
    transform: translateX(-12%);
  }
  .home__hero-img1{
    transform: translate(-12%,0%);
  }
  .bts{
    transform: translateX(-8%);
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height:1050px){
  .row1{
    flex-flow: row;
  }
  .top-line1{
    font-size: 55px;
    line-height: 62px;
  }
  .heading2{
    font-size: 55px;
  }
  .heading1{
    font-size: 30px;
    transform: translate(0%,25%);
  }
  .home__hero-subtitle1{
    font-size: 20px;
    transform: translatex(0%);
    width: 105%;
    text-align: justify;
  }
  .home__hero-text-wrapper1{
    transform: translateX(0%);
  }
  .home__hero-img1{
    transform: translate(0%,0%);
  }
  .bts{
    transform: translateX(-8%);
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height:1024px){
  .row1{
    flex-flow: row;
  }
  .top-line1{
    font-size: 55px;
    line-height: 62px;
  }
  .heading2{
    font-size: 55px;
  }
  .heading1{
    font-size: 30px;
    transform: translate(0%,25%);
  }
  .home__hero-subtitle1{
    font-size: 20px;
    transform: translatex(0%);
    width: 105%;
    text-align: justify;
  }
  .home__hero-text-wrapper1{
    transform: translateX(0%);
  }
  .home__hero-img1{
    transform: translate(0%,0%);
  }
  .bts{
    transform: translateX(-8%);
  }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height:1050px){
  .row1{
    flex-flow: row;
  }
  .top-line1{
    font-size: 55px;
    line-height: 62px;
  }
  .heading2{
    font-size: 55px;
  }
  .heading1{
    font-size: 30px;
    transform: translate(0%,25%);
  }
  .home__hero-subtitle1{
    font-size: 20px;
    transform: translatex(0%);
    width: 105%;
    text-align: justify;
  }
  .home__hero-text-wrapper1{
    transform: translateX(0%);
  }
  .home__hero-img1{
    transform: translate(0%,0%);
  }
  .bts{
    transform: translateX(-8%);
  }
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px)  and (max-device-height: 1080px){
  .row1{
    flex-flow: row;
  }
  .top-line1{
    font-size: 55px;
    line-height: 62px;
  }
  .heading2{
    font-size: 55px;
  }
  .heading1{
    font-size: 30px;
    transform: translate(0%,25%);
  }
  .home__hero-subtitle1{
    font-size: 21px;
    transform: translatex(-10%);
    width: 130%;
    text-align:justify;
  }
  .home__hero-text-wrapper1{
    transform: translateX(5%);
  }
  .home__hero-img1{
    transform: translate(-0%,-15%);
  }
  .bts{
    transform: translateX(-8%);
  }
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  .row1{
    flex-flow: row;
  }
  .top-line1{
    font-size: 55px;
    line-height: 62px;
  }
  .heading2{
    font-size: 55px;
  }
  .heading1{
    font-size: 30px;
    transform: translate(0%,25%);
  }
  .home__hero-subtitle1{
    font-size: 21px;
    transform: translatex(-10%);
    width: 130%;
  }
  .home__hero-text-wrapper1{
    transform: translateX(5%);
  }
  .home__hero-img1{
    transform: translate(-5%,-15%);
  }
  .bts{
    transform: translateX(-8%);
  }
}



