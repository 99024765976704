* {
  margin: 0;
  padding: 0;
}

.home__hero-sectionp {
  color: #fff;
  padding: 160px 0;
}

.home__hero-rowp {
  align-items: center;
}

.rowp {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: row  ;
  align-content: stretch;
}

.colpt {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapperpt {
  padding-top: 0;
  padding-bottom: 60px;
  margin-left: 15%;
}

.dark {
  color: #00642f;
}

.newBg{
  background-color: #ffeb98;
}

.darkBg {
  background-color: #00642f;
}

.home__hero-subtitlept {
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

/* .home__hero-img-wrapperpt {
} */

.home__hero-imgpt {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

/* img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
} */

@media  only screen and (min-device-width: 0px) and (min-device-height: 0px) and (max-device-width: 320px) and (max-device-height: 790px) {
  .rowp{
    flex-flow: column-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-70%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,-20%);
    width: 224%;
    text-align: justify;
    font-size: 85%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-40%);
    /* text-align: center; */
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, 310%);
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 224%;
    text-align: justify;
    font-size: 85%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -30%)
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 224%;
    text-align: justify;
    font-size: 85%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 840px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:801px) and (max-device-width: 385px) and (max-device-height: 999px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-16%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-10%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-10%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-18%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-10%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-10%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-10%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-10%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-10%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-10%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 224%;
    text-align: justify;
    font-size: 94%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-15%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-20%,0%);
    width: 224%;
    text-align: justify;
    font-size: 94%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 198%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-20%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-25%,0%);
    width: 224%;
    text-align: justify;
    font-size: 94%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, -20%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 290%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-20%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-25%,0%);
    width: 224%;
    text-align: justify;
    font-size: 110%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, 0%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 290%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-20%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-25%,0%);
    width: 224%;
    text-align: justify;
    font-size: 110%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, 0%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 290%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-20%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-25%,0%);
    width: 224%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, 0%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 290%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-20%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-25%,0%);
    width: 224%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, 0%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .rowp{
    flex-flow: column;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 290%;
    text-align: inherit;
    line-height: 1.1;
    transform: translate(-20%,-30%);
    width: 205%;
    font-weight: 600;
  }
  .home__hero-subtitlept{
    transform: translate(-25%,0%);
    width: 224%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 125%;
    transform: translate(-15%, 0%)
  }
  .home__hero-text-wrapperpt{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-30%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 80%;
    transform: translate(10%, 80%);
    margin-bottom: 45%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-35%);
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-30%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 80%;
    transform: translate(10%, 80%);
    margin-bottom: 45%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-35%);
  }
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-30%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 80%;
    transform: translate(10%, 80%);
    margin-bottom: 45%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-35%);
  }
}


/*desktops*/
@media only screen and (min-device-width: 1280px) and (min-device-height: 900px) and (max-device-width: 1280px) and (max-device-height: 1024px){
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-30%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 80%;
    transform: translate(10%, 90%);
    margin-bottom: 60%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-35%);
  }
}
@media only screen and (min-device-width: 1280px) and (max-device-width: 1366px) and (max-device-height: 768px){
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-30%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 60%;
    transform: translate(10%, 65%);
    margin-bottom: 35%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-55%);
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 119%;
    transform: translate(10%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 60%;
    transform: translate(10%, 41%);
    margin-bottom: 10%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-45%);
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height: 1024px){
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 119%;
    transform: translate(10%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 60%;
    transform: translate(10%, 41%);
    margin-bottom: 10%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-45%);
  }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height: 1050px){
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 119%;
    transform: translate(10%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 60%;
    transform: translate(10%, 35%);
    margin-bottom: 10%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-45%);
  }
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px) and (max-device-height: 1080px){
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 119%;
    transform: translate(10%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 60%;
    transform: translate(10%, 35%);
    margin-bottom: 10%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-50%);
  }
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  .rowp{
    flex-flow: row-reverse;
  }
  .headingPt{
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 119%;
    transform: translate(10%, -20%);
    text-align: end;
  }
  .home__hero-subtitlept{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpt{
    width: 60%;
    transform: translate(10%, 35%);
    margin-bottom: 10%;
  }
  .home__hero-text-wrapperpt{
    transform: translate(-38%,-50%);
  }
}
