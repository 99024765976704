* {
  margin: 0;
  padding: 0;
}

.home__hero-section {
  color: #fff;
  padding: 160px 0;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: row  ;
  align-content: stretch;
}

.col .col1 .col2 {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}


.home__hero-text-wrapper {
  padding-top: 0;
  padding-bottom: 60px;
}

.heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff603;
  transform: translateX(7%);
}

.dark {
  color: #00642f;
}

.newBg{
  background-color: #ffeb98;
}

.darkBg {
  background-color: #00642f;
}

.home__hero-subtitle {
  margin-bottom: 35px;
  font-size: 18px;
  /* line-height: 24px; */
}
.home__hero-subtitle.dark{
    margin-bottom: 35px;
    font-size: 18px;
    text-align: justify;
}

@keyframes display {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  10% {
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    transform: translateX(0);
    opacity: 1;
  }
  30% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(-200px);
    opacity: 0;
  }
}

.pic-ctn {
  position: absolute;
  width: 100px;
  height: 300px;
  margin-top: 15vh;
  transform: translate(-60%, -115%);
}

.pic-ctn > img {
  position: absolute;
  top: 0;
  left: calc(50% - 100px);
  opacity: 0;
  animation: display 10s infinite;
  width: 650px;
  height: 325px;
}

img:nth-child(2) {
  animation-delay: 2s;
}
img:nth-child(3) {
  animation-delay: 4s;
}
img:nth-child(4) {
  animation-delay: 6s;
}
img:nth-child(5) {
  animation-delay: 8s;
}

.zomato{
  position: relative;
  width: 40%;
  transform: translate(210%, 134%);
}
.zline{
color: #fff603;
width: 300%;
font-size: 20px;
margin-bottom: 25%;
transform: translate(21%,80%);
}
.logos{
  flex-direction: row;
  display: flex;
}
.zlogo{
  width: 60%;
  height: 50%;
  margin-right: 20%;
}
.slogo{
  width: 130%;
  height: 100%;
}
.btn2{
  transform: translate(25%, -25%);
}

/* For mobile devices */
@media  only screen and (min-device-width: 0px) and (min-device-height: 0px) and (max-device-width: 320px) and (max-device-height: 568px) {
  .col{
    transform: translate(30px, -130px);
  }
  .heading{
    margin-left: 20px;
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(20%, -80%);
  }
  .zomato {
    width: 41%;
    transform: translate(0%,-10%);
  }
  .logos{
    margin-top: 20px;
    width: 50%;
    margin-left: 20px;
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 0px) and (min-device-height: 568px) and (max-device-width: 320px) and (max-device-height: 790px) {
  .col{
    transform: translate(30px, -130px);
  }
  .heading{
    margin-left: 20px;
  }
  .home__hero-text-wrapper {
    transform: translate(-10%, 5%);
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(20%, -80%);
  }
  .zomato {
    width: 41%;
    transform: translate(5%,15%);
  }
  .logos{
    margin-top: 20px;
    transform: translateX(65%);
    width: 40%;
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .col{
    transform: translate(40px, -130px);
  }
  .heading{
    margin-left: 20px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(45%, -90%);
  }
  .zomato {
    width: 41%;
    transform: translate(60%,-30%);
  }
  .zline{
    transform: translate(8%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .col{
    transform: translate(40px, -130px);
  }
  .heading{
    margin-left: 20px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(45%, -95%);
  }
  .zomato {
    width: 41%;
    transform: translate(60%,-30%);
  }
  .zline{
    transform: translate(8%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .col{
    transform: translate(40px, -130px);
  }
  .heading{
    margin-left: 20px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 300px;
    height: 190px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(40%, -90%);
  }
  .zomato {
    width: 41%;
    transform: translate(60%,10%);
  }
  .zline{
    transform: translate(8%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 840px){
  .col{
    transform: translate(40px, -130px);
  }
  .heading{
    margin-left: 20px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 315px;
    height: 200px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(20%, -95%);
  }
  .zomato {
    width: 41%;
    transform: translate(60%,10%);
  }
  .zline{
    transform: translate(8%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -90%);
  }
  .zomato {
    width: 41%;
    transform: translate(5%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(205%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -100%);
  }
  .zomato {
    width: 41%;
    transform: translate(0%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(230%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:800px) and (max-device-width: 385px) and (max-device-height: 999px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -105%);
  }
  .zomato {
    width: 41%;
    transform: translate(0%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -96%);
  }
  .zomato {
    width: 41%;
    transform: translate(0%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(240%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -95%);
  }
  .zomato {
    width: 41%;
    transform: translate(0%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 280px;
    height: 170px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -100%);
  }
  .zomato {
    width: 41%;
    transform: translate(0%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 320px;
    height: 190px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -100%);
  }
  .zomato {
    width: 41%;
    transform: translate(0%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 320px;
    height: 190px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -104%);
  }
  .zomato {
    width: 41%;
    transform: translate(-12%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 320px;
    height: 190px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -95%);
  }
  .zomato {
    width: 41%;
    transform: translate(-12%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 310px;
    height: 190px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -111%);
  }
  .zomato {
    width: 41%;
    transform: translate(-12%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 320px;
    height: 190px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -99%);
  }
  .zomato {
    width: 41%;
    transform: translate(-12%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 320px;
    height: 190px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -104%);
  }
  .zomato {
    width: 41%;
    transform: translate(-12%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 320px;
    height: 190px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -108%);
  }
  .zomato {
    width: 41%;
    transform: translate(-12%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(280%, -10%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .col{
    transform: translate(55px, -130px);
  }
  .heading{
    margin-left: 30px;
  }
  .home__hero-subtitle {
    transform: translateX(-10%);
  }
  .pic-ctn > img{
    width: 320px;
    height: 190px;
    border-radius: 50px;
  }
  .pic-ctn {
    transform: translate(65%, -105%);
  }
  .zomato {
    width: 41%;
    transform: translate(-12%,-25%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(370%, -30%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .col{
    transform: translate(4%, -35%)
  }
  .heading{
    margin-left: 30%;
  }
  .pic-ctn > img{
    width: 480px;
    height: 290px;
    border-radius: 50%;
  }
  .pic-ctn {
    transform: translate(35%, -112%);
  }
  .zomato {
    width: 41%;
    transform: translate(-5%,40%);
  }
  .zline{
    transform: translate(35%,60%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(480%, -40%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .col{
    transform: translate(70%, -35%);
  }
  .heading{
    margin-left: 20%;
  }
  .home__hero-subtitle {
    width: 130%;
    margin-left: -15%;
  }
  .pic-ctn > img{
    width: 400px;
    height: 250px;
    border-radius: 50%;
  }
  .pic-ctn {
    transform: translate(-120%, -50%);
  }
  .zomato {
    width: 41%;
    transform: translate(30%,40%);
  }
  .zline{
    transform: translate(29%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(640%, -80%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .col{
    transform: translate(60%, -35%);
  }
  .heading{
    margin-left: 20%;
  }
  .home__hero-subtitle {
    width: 135%;
    margin-left: -20%;
  }
  .pic-ctn > img{
    width: 540px;
    height: 330px;
    border-radius: 50%;
  }
  .pic-ctn {
    transform: translate(-210%, -78%);
  }
  .zomato {
    width: 41%;
    transform: translate(20%,50%);
  }
  .zline{
    transform: translate(29%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(720%, -85%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .col{
    transform: translate(60%, -35%);
  }
  .heading{
    margin-left: 20%;
  }
  .home__hero-subtitle {
    width: 135%;
    margin-left: -20%;
  }
  .pic-ctn > img{
    width: 540px;
    height: 330px;
    border-radius: 50%;
  }
  .pic-ctn {
    transform: translate(-210%, -80%);
  }
  .zomato {
    width: 41%;
    transform: translate(20%,50%);
  }
  .zline{
    transform: translate(29%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(720%, -85%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .col{
    transform: translate(60%, -35%);
  }
  .heading{
    margin-left: 20%;
  }
  .home__hero-subtitle {
    width: 135%;
    margin-left: -20%;
  }
  .pic-ctn > img{
    width: 540px;
    height: 330px;
    border-radius: 50%;
  }
  .pic-ctn {
    transform: translate(-210%, -80%);
  }
  .zomato {
    width: 41%;
    transform: translate(20%,50%);
  }
  .zline{
    transform: translate(29%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(720%, -85%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .col{
    transform: translate(60%, -35%);
  }
  .heading{
    margin-left: 20%;
  }
  .home__hero-subtitle {
    width: 135%;
    margin-left: -20%;
  }
  .pic-ctn > img{
    width: 540px;
    height: 330px;
    border-radius: 50%;
  }
  .pic-ctn {
    transform: translate(-210%, -80%);
  }
  .zomato {
    width: 41%;
    transform: translate(20%,50%);
  }
  .zline{
    transform: translate(29%,80%);
    width: 327%;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(720%, -85%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media only screen and (min-device-width: 835px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .col{
    transform: translate(60%, -50%);
  }
  .home__hero-text-wrapper {
    transform: translate(8%, 10%);
  }
  .heading{
    margin-left: 15%;
  }
  .home__hero-subtitle {
    width: 117%;
  }
  .pic-ctn > img{
    width: 580px;
    height: 320px;
    border-radius: 28%;
  }
  .pic-ctn {
    transform: translate(-190%, -78%);
  }
  .zomato {
    width: 41%;
    transform: translate(20%,95%)
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
    font-size: 23px;
    margin-bottom: 23px;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(975%, 5%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .col{
    transform: translate(60%, -50%);
  }
  .heading{
    margin-left: 30%;
  }
  .home__hero-subtitle {
    width: 103%;
  }
  .pic-ctn > img{
    width: 580px;
    height: 320px;
    border-radius: 28%;
  }
  .pic-ctn {
    transform: translate(-190%, -58%);
  }
  .zomato {
    width: 41%;
    transform: translate(20%,85%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
    font-size: 23px;
    margin-bottom: 23px;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(1130%, 5%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 1024px){
  /* .container {
    transform: translateY(80px);
  } */
  .col{
    transform: translate(135%, -15%);
  }
  .pic-ctn {
    transform: translate(-593%, -110%);
  }
  .pic-ctn > img {
    border-radius: 15%;
    width: 580px;
    height: 320px;
  }
  .home__hero-text-wrapper {
    transform: translate(-10%, 0%);
  }
  .heading {
    transform: translateX(15%);
  }
  .home__hero-subtitle {
    width: 75%;
  }
  .zomato {
    width: 25%;
    transform: translate(225%, -100%);
  }
  .zline {
    font-size: 26px;
    transform: translate(22%, 260%);
  }
  .logos{
    width: 20%;
    transform: translate(265%,10%);
  }
  .slogo {
    transform: translateY(20%);
  }
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
  .col{
    transform: translate(60%, -50%);
  }
  .heading{
    margin-left: 35%;
  }
  .home__hero-subtitle {
    width: 88%;
    margin-left: 20%;
  }
  .pic-ctn > img{
    width: 580px;
    height: 320px;
    border-radius: 28%;
  }
  .pic-ctn {
    transform: translate(-190%, -66%);
  }
  .zomato {
    width: 41%;
    transform: translate(20%,85%);
  }
  .zline{
    transform: translate(30%,80%);
    width: 327%;
    font-size: 23px;
    margin-bottom: 23px;
  }
  .logos{
    margin-top: 20px;
    width: 35px;
    transform: translate(1165%, 5%);
  }
  .zlogo {
    width: 160%;
  }
  .slogo {
    width: 320%;
    transform: translateY(10px);
  }
}

/*desktops*/

@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
  /* .container {
    transform: translateY(80px);
  } */
  .col{
    transform: translate(135%, -15%);
  }
  .pic-ctn {
    transform: translate(-590%, -105%);
  }
  .pic-ctn > img {
    border-radius: 15%;
  }
  .heading {
    transform: translateX(30%);
  }
  .home__hero-subtitle {
    width: 75%;
  }
  .zomato {
    width: 25%;
    transform: translate(225%, -100%);
  }
  .zline {
    font-size: 26px;
    transform: translate(22%, 260%);
  }
  .logos{
    width: 20%;
    transform: translate(265%,10%);
  }
  .slogo {
    transform: translateY(20%);
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
  /* .container {
    transform: translateY(80px);
  } */
  .col{
    transform: translate(135%, -15%);
  }
  .pic-ctn {
    transform: translate(-620%, -105%);
  }
  .pic-ctn > img {
    border-radius: 15%;
    width: 580px;
    height: 320px;
  }
  .heading {
    transform: translateX(25%);
  }
  .home__hero-subtitle {
    width: 85%;
    margin-left: 5%;
  }
  .zomato {
    width: 25%;
    transform: translate(250%, -100%);
  }
  .zline {
    font-size: 26px;
    transform: translate(22%, 260%);
  }
  .logos{
    width: 20%;
    transform: translate(265%,10%);
  }
  .slogo {
    transform: translateY(20%);
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height: 1024px){
  /* .container {
    transform: translateY(80px);
  } */
  .col{
    transform: translate(130%, -15%);
  }
  .home__hero-subtitle {
    width: 80%;
    margin-left: 15%;
  }
  .pic-ctn {
    transform: translate(-580%, -120%);
  }
  .pic-ctn > img {
    border-radius: 15%;
    width: 660px;
    height: 400px;
  }
  .heading {
    transform: translateX(30%);
  }
  .zomato {
    width: 20%;
    transform: translate(340%, -65%);
    margin-bottom: -15%;
  }
  .zline {
    font-size: 26px;
    transform: translate(22%, 10%);
  }
  .logos{
    width: 26%;
    transform: translate(190%,-50%);
  }
  .slogo {
    transform: translateY(20%);
  }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height: 1050px){
  /* .container {
    transform: translateY(80px);
  } */
  .col{
    transform: translate(130%, -15%);
  }
  .home__hero-subtitle {
    width: 80%;
    margin-left: 15%;
  }
  .pic-ctn {
    transform: translate(-580%, -120%);
  }
  .pic-ctn > img {
    border-radius: 15%;
    width: 660px;
    height: 400px;
  }
  .heading {
    transform: translateX(30%);
  }
  .zomato {
    width: 25%;
    transform: translate(260%, -100%);

  }
  .zline {
    font-size: 26px;
    transform: translate(22%, 260%);
  }
  .logos{
    width: 20%;
    transform: translate(265%,10%);
  }
  .slogo {
    transform: translateY(20%);
  }
  .btm{
    transform: translateX(10%);
  }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1920px) and (max-device-height: 1080px){
  /* .container {
    transform: translateY(80px);
  } */
  .col{
    transform: translate(130%, -15%);
  }
  .home__hero-subtitle {
    width: 80%;
    margin-left: 15%;
  }
  .pic-ctn {
    transform: translate(-580%, -120%);
  }
  .pic-ctn > img {
    border-radius: 15%;
    width: 660px;
    height: 400px;
  }
  .heading {
    transform: translateX(30%);
  }
  .zomato {
    width: 25%;
    transform: translate(260%, -100%);

  }
  .zline {
    font-size: 26px;
    transform: translate(22%, 260%);
  }
  .logos{
    width: 20%;
    transform: translate(265%,10%);
  }
  .slogo {
    transform: translateY(20%);
  }
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  /* .container {
    transform: translateY(80px);
  } */
  .col{
    transform: translate(130%, -15%);
  }
  .home__hero-text-wrapper {
    transform: translate(35%, 0%);
  }
  .home__hero-subtitle {
    width: 115%;
    margin-left: 5%;
    font-size: 26px;
  }
  .pic-ctn {
    transform: translate(-860%, -120%);
  }
  .pic-ctn > img {
    border-radius: 15%;
    width: 890px;
    height: 470px;
  }
  .heading {
    transform: translateX(30%);
    font-size: 70px;
  }
  .zomato {
    width: 25%;
    transform: translate(335%, -100%)

  }
  .zline {
    font-size: 30px;
    transform: translate(20%, 230%);
  }
  .logos{
    width: 20%;
    transform: translate(250%,25%);
  }
  .slogo {
    transform: translateY(20%);
  }
}