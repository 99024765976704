* {
  margin: 0;
  padding: 0;
}

.home__hero-section1 {
  color: #fff;
  padding: 160px 0;
}

.home__hero-row1 {
  align-items: center;
}

.row1 {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: row  ;
  /* flex-flow: row-reverse; */
  align-content: stretch;
}

.col1 {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapperi1 {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  margin-left: 15%;
}

.headingth1 {
  color: #fff603;
}

.dark {
  color: #00642f;
}

.newBg{
  background-color: #ffeb98;
}

.darkBg {
  background-color: #00642f;
}

.home__hero-subtitleth1 {
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  transform :translateX(15%);
}


@media  only screen and (min-device-width: 0px) and (max-device-width: 320px) and (max-device-height: 790px) {
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 211%;
    transform: translate(-28%, -80%);
  }
  .home__hero-subtitleth1{
    transform: translate(-27%,-15%);
    width: 224%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-18%);
  }

  .btn1{
    transform: translateX(-40%);
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -85%);
  }
  .home__hero-subtitleth1{
    transform: translate(-25%,-15%);
    width: 210%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-18%);
  }

  .btn1{
    transform: translateX(-40%);
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -85%);
  }
  .home__hero-subtitleth1{
    transform: translate(-25%,-15%);
    width: 210%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-18%);
  }
  .btn1{
    transform: translateX(-40%);
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    
    text-align: center;
    width: 210%;
    transform: translate(-26%, -55%)
  }
  .home__hero-subtitleth1{
    transform: translate(-25%,0%);
    width: 210%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-25%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 840px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-25%,0%);
    width: 210%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-25%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-25%,0%);
    width: 210%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-25%,-5%);
    width: 210%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-25%);
    width: 202%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:801px) and (max-device-width: 385px) and (max-device-height: 999px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-25%);
    width: 202%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 385px) and (max-device-width: 390px) and (max-device-height: 722px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-25%);
    width: 202%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-27%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-27%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-25%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-27%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-27%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }  
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-27%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-27%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-27%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-27%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-10%,-5%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-28%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-15%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 163%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translateX(-28%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-15%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-15%,5%);
    width: 155%;
    text-align: justify;
    font-size: 110%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-25%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-15%,5%);
    width: 155%;
    text-align: justify;
    font-size: 120%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-20%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-24%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-15%,5%);
    width: 155%;
    text-align: justify;
    font-size: 120%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-20%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-24%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-15%,5%);
    width: 155%;
    text-align: justify;
    font-size: 120%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-20%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-24%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-20%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .row1{
    flex-flow: column-reverse;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
  .home__hero-text-wrapperi1{
    transform: translate(-8%,-20%);
  }
  .btn1{
    transform: translateX(-30%);
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .row1{
    flex-flow: row;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
  .row1{
    flex-flow: row;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
}

/*desktops*/
@media only screen and (min-device-width: 1153px) and (min-device-height: 901px)  and (max-device-width: 1280px) and (max-device-height: 1024px){
  .row1{
    flex-flow: row;
  }
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height:1050px){
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1680px) and (max-device-height:1050px){
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px)  and (max-device-height: 1080px){
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  .headingth1 {
    margin-bottom: 24px;
    font-size: 205%;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    width: 210%;
    transform: translate(-26%, -35%)
  }
  .home__hero-subtitleth1{
    transform: translate(-19%,5%);
    width: 165%;
    text-align: justify;
    font-size: 120%;
  }
}



