.navbar {
  background: #00642f;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  justify-content: center;
}


.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  width: 100px;
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #f00946;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media  only screen and (min-device-width: 0px) and (min-device-height: 0px) and (max-device-width: 320px) and (max-device-height: 790px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-280%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-170%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-170%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-270%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 801px) and (max-device-width: 360px) and (max-device-height: 840px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-270%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-170%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-170%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-270%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:800px) and (max-device-width: 385px) and (max-device-height: 999px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-470%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-170%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-350%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-230%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-320%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-230%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-480%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(-5%,-230%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-380%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-420%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-320%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-220%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(10%,-500%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-420%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-500%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-420%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-500%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-630%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-180%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    transform: translateY(-8px);
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    transform: translate(0%,-300%);
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 65%;
  }

  .navbar-icon {
    position: absolute;
    width: 12vh;
    left: -80px;
    top: -15px;
    transform: translate(0%, 10%);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /* left: -100px; */
    transform: translate(25%, 50%);
  }

  .btext{
    transform: translate(-3%,50%);
    font-size: 60%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 15%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}


/*desktops*/
@media only screen and (min-device-width: 1152px) and (max-device-width: 1280px) and (max-device-height:1024px){
  .navbar-container{
    margin-left: 34%;
  }
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
  .navbar-container{
    margin-left: 34%;
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
  .navbar-container{
    margin-left: 34%;
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height: 1024px){
  .navbar-container{
    margin-left: 34%;
  }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height: 1050px){
  .navbar-container{
    margin-left: 34%;
  }
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px) and (max-device-height: 1080px) {
  .navbar-container{
    margin-left: 34%;
  }
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  .navbar-container{
    margin-left: 34%;
  }
}


