* {
  margin: 0;
  padding: 0;
}

.home__hero-sectionp {
  color: #fff;
  padding: 160px 0;
}

.home__hero-rowp {
  align-items: center;
}

.rowp {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: row  ;
  align-content: stretch;
}

.colp {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapperp {
  padding-top: 0;
  padding-bottom: 60px;
  margin-left: 15%;
}


.headinghp.dark {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
}

.dark {
  color: #00642f;
}

.newBg{
  background-color: #ffeb98;
}

.darkBg {
  background-color: #00642f;
}

.home__hero-subtitlep {
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

/* .home__hero-img-wrapperp {
} */

.home__hero-imgp {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

/* img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
} */

@media  only screen and (min-device-width: 0px) and (min-device-height: 0px) and (max-device-width: 320px) and (max-device-height: 790px) {
  .rowp{
    flex-flow: column-reverse;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 192%;
    margin-left: -30%;
  }
  .home__hero-subtitlep.dark{
    transform: translateX(-18%);
    width: 195%;
    text-align: justify;
    font-size: 85%;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-65%);
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, 190%);
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 175%;
    margin-left: -30%;
  }
  .home__hero-subtitlep{
    transform: translateX(-18%);
    width: 195%;
    text-align: justify;
    font-size: 85%;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 175%;
    margin-left: -25%;
  }
  .home__hero-subtitlep{
    transform: translateX(-17%);
    width: 186%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 175%;
    margin-left: -25%;
  }
  .home__hero-subtitlep{
    transform: translateX(-17%);
    width: 186%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 840px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 175%;
    transform: translateX(-15%);
  }
  .home__hero-subtitlep{
    transform: translateX(-17%);
    width: 186%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 161%;
    margin-left: -18%;
  }
  .home__hero-subtitlep{
    transform: translateX(-20%);
    width: 228%;
    text-align: justify;
    font-size: 104%;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 161%;
    margin-left: -18%;
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 190%;
    text-align: justify;
    font-size: 120%;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
    margin-left: -18%;
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 187%;
    text-align: justify;
    font-size: 120%;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:801px) and (max-device-width: 385px) and (max-device-height: 999px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
    margin-left: -18%;
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 187%;
    text-align: justify;
    font-size: 120%;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
    margin-left: -8%;
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 187%;
    text-align: justify;
    font-size: 90%;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
    margin-left: -8%;
  }
  .home__hero-subtitlep{
    transform: translateX(-8%);
    width: 170%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
  }
  .home__hero-subtitlep{
    transform: translateX(-19%);
    width: 221%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
  }
  .home__hero-subtitlep{
    transform: translateX(-19%);
    width: 221%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
  }
  .home__hero-subtitlep{
    transform: translateX(-19%);
    width: 221%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
  }
  .home__hero-subtitlep{
    transform: translateX(-19%);
    width: 221%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
  }
  .home__hero-subtitlep{
    transform: translateX(-19%);
    width: 220%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
  }
  .home__hero-subtitlep{
    transform: translateX(-19%);
    width: 220%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
  }
  .home__hero-subtitlep{
    transform: translateX(-19%);
    width: 212%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
  }
  .home__hero-subtitlep{
    transform: translateX(-17%);
    width: 189%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    width: 160%;
  }
  .home__hero-subtitlep{
    transform: translateX(-17%);
    width: 189%;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -30%)
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlep{
    transform: translateX(-20%);
    width: 490px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -30%)
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlep{
    transform: translateX(-20%);
    width: 490px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -30%)
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlep{
    transform: translateX(-20%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -30%)
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -30%)
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .rowp{
    flex-flow: column;
  }
  .headinghp.dark{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
  .home__hero-imgp{
    width: 125%;
    transform: translate(-15%, -30%)
  }
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 35px;
    transform: translateX(0%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 35px;
    transform: translateX(0%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 35px;
    transform: translateX(0%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}


/*desktops*/
@media only screen and (min-device-width: 1152px) and (max-device-width: 1280px) and (max-device-height: 1024px){
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 35px;
    transform: translateX(0%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 35px;
    transform: translateX(0%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 35px;
    transform: translateX(0%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height: 1024px){
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 35px;
    transform: translateX(15%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height: 1050px){
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 35px;
    transform: translateX(18%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px) and (max-device-height: 1080px){
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 35px;
    transform: translateX(20%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 507px;
    text-align: justify;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  .rowp{
    flex-flow: row;
  }
  .headinghp.dark{
    font-size: 70px;
    transform: translateX(20%);
  }
  .home__hero-subtitlep{
    transform: translateX(-15%);
    width: 81%;
    text-align: justify;
    font-size: 26px;
  }
  .home__hero-text-wrapperp{
    transform: translate(5%,-15%);

  }
  .home__hero-imgp{
    width: 70%;
    transform: translate(20%,10%);
  }
}
