* {
  margin: 0;
  padding: 0;
}

.home__hero-sectiona {
  color: #fff;
  padding: 160px 0;
}

.home__hero-rowa {
  align-items: center;
  
}

.rowa {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: row  ;
  flex-direction: column;
  align-content: stretch;
}

.cola {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrappera {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  margin-left: 15%;
}

.top-line {
  color: #fff603;
  font-size: 25px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.headinga {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff603;
  font-size: 30px;
  transform: translateX(30%);
}

.headinga.dark {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff603;
  font-size: 30px;
  transform: translateX(-13%);
}

.dark {
  color: #00642f;
}

.newBg{
  background-color: #ffeb98;
}

.darkBg {
  background-color: #00642f;
}

.home__hero-subtitlea {
  margin-bottom: 35px;
  font-size: 18px;
  /* line-height: 24px; */
}

.home__hero-img-wrappera {
  max-width: 555px;
}

.home__hero-imga {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

.btn3--wide{
  transform: translate(45%,-45%);
}

@media  only screen and (min-device-width: 0px) and (min-device-height: 0px) and (max-device-width: 320px) and (max-device-height: 790px) {
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(15%); 
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -105%;
  }
  .home__hero-subtitlea{
    transform: translateX(-20%);
    width: 185%;
    text-align: justify;
    font-size: 13px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 221%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 221%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 221%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 801px) and (max-device-width: 360px) and (max-device-height: 840px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 221%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 225%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 225%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 221%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:800px) and (max-device-width: 385px) and (max-device-height: 999px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 221%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 221%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 221%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitlea{
    transform: translateX(-25%);
    width: 221%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(22%);
    font-size: 25px;  
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -120%;
  }
  .home__hero-subtitlea{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 15px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(22%);
    font-size: 25px;  
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -120%;
  }
  .home__hero-subtitlea{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 15px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(22%);
    font-size: 25px;  
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -120%;
  }
  .home__hero-subtitlea{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 15px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(22%);
    font-size: 25px;  
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -120%;
  }
  .home__hero-subtitlea{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 15px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(22%);
    font-size: 25px;  
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -120%;
  }
  .home__hero-subtitlea{
    transform: translateX(-24%);
    width: 217%;
    text-align: justify;
    font-size: 15px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(22%);
    font-size: 25px;  
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -120%;
  }
  .home__hero-subtitlea{
    transform: translateX(-24%);
    width: 217%;
    text-align: justify;
    font-size: 15px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .rowa{
    flex-flow: column-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(22%);
    font-size: 25px;  
  }
  .headinga{
    font-size: 35px;
    text-align: center;
    margin-left: -120%;
  }
  .home__hero-subtitlea{
    transform: translateX(-24%);
    width: 217%;
    text-align: justify;
    font-size: 15px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imga{
    width: 125%;
    transform: translate(-18%, -50%)
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .rowa{
    flex-flow: column;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(26%);
    font-size: 22px; 
  }
  .headinga{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlea{
    transform: translateX(-15%);
    width: 217%;
    text-align: justify;
    font-size: 15px;
  }
  .home__hero-text-wrappera{
    transform: translate(-46%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 110%;
    transform: translate(-18%, -25%)
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .rowa{
    flex-flow: column;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(28%);
    font-size: 25px; 
  }
  .headinga{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlea{
    transform: translateX(-12%);
    width: 217%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrappera{
    transform: translate(-51%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 110%;
    transform: translate(-18%, -25%)
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .rowa{
    flex-flow: column;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(28%);
    font-size: 25px; 
  }
  .headinga{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlea{
    transform: translateX(-12%);
    width: 217%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrappera{
    transform: translate(-51%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 110%;
    transform: translate(-18%, -25%)
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .rowa{
    flex-flow: column;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(28%);
    font-size: 25px; 
  }
  .headinga{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlea{
    transform: translateX(-12%);
    width: 217%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrappera{
    transform: translate(-51%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 110%;
    transform: translate(-18%, -25%)
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .rowa{
    flex-flow: column;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(28%);
    font-size: 25px; 
  }
  .headinga{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlea{
    transform: translateX(-12%);
    width: 217%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrappera{
    transform: translate(-51%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 110%;
    transform: translate(-18%, -25%)
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .rowa{
    flex-flow: column;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    transform: translateX(28%);
    font-size: 25px; 
  }
  .headinga{
    font-size: 35px;
    text-align: center;
  }
  .home__hero-subtitlea{
    transform: translateX(-12%);
    width: 217%;
    text-align: justify;
    font-size: 16px;
  }
  .home__hero-text-wrappera{
    transform: translate(-51%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 110%;
    transform: translate(-18%, -25%)
  }
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}


/*desktops*/
@media only screen and (min-device-width: 1152px) and (max-device-width: 1280px) and (max-device-height:1024px){
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height: 1024px){
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height: 1050px){
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px) and (max-device-height: 1080px) {
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  .rowa{
    flex-flow: row-reverse;
    transform: translate(5%, 0%);
  }
  .top-line{
    margin-top: 15%;
    font-size: 25px; 
    transform: translateX(-11%);
  }
  .headinga{
    font-size: 35px;
    text-align: right;
    transform: translate(-40%, 10%);
  }
  .home__hero-subtitlea{
    width: 123%;
    text-align: justify;
    transform: translateX(-10%);
    font-size: 18px;
  }
  .home__hero-text-wrappera{
    transform: translate(-25%,-20%);
    text-align: center;
  }
  .home__hero-imga{
    width: 80%;
    transform: translate(-10%, -15%);
  }
}
