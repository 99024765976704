.footer-container {
  background-color: #00642f;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  align-items: center;
}

.footer-link-items1{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 85%;
  box-sizing: border-box;
  align-items: center;
}

.footer-link-items h2,.footer-link-items1 h2 {
  margin-bottom: 16px;
}

.footer-link-items>h2,.footer-link-items1 > h2 {
  color: #fff;
}

.footer-link-items a,.footer-link-items1 a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover,.footer-link-items1 a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-icon1-linkInsta{
  color: #fff;
  font-size: 24px;
  transform: translateX(-720%);
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}


.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 359px){
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }
  .footer-link-items1{

  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    flex-direction: column;
    margin-right: -1rem;
    margin-top: -6rem;
  }
  .footer-link-items1{
    transform: translateX(-15%);
  }
  .social-media-wrap {
    flex-direction: column;
  }
  .social-icons{
    transform: translateX(40%);
  }
  .social-logo{
    transform: translateX(-5%);
    font-size: 30px;
  }
}

@media screen and (min-device-width: 360px) and (max-device-width: 410px){
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }
  .footer-link-items1{
    transform: translateY(0%);
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    flex-direction: column;
    margin-right: 0rem;
    margin-top: -6rem;
  }

  .social-media-wrap {
    flex-direction: column;
  }
  .social-icons{
    transform: translateX(40%);
  }
}
@media screen and (min-device-width: 411px) and (max-device-width: 539px){
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }
  .footer-link-items1{
    transform: translateY(0%);
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    flex-direction: column;
    margin-right: 0rem;
    margin-top: -6rem;
  }

  .social-media-wrap {
    flex-direction: column;
  }
  .social-icons{
    transform: translateX(40%);
  }
}
@media screen and (min-device-width: 540px) and (max-device-width: 767px){
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }
  .footer-link-items1{
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    flex-direction: column;
    margin-left: 4rem;
    margin-top: -6rem;
  }

  .social-media-wrap {
    flex-direction: column;
  }
  .social-icons{
    transform: translateX(40%);
  }
  .social-logo{
    font-size: 3rem;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1023px){
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }
  .footer-link-items1{

  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    flex-direction: column;
    margin-left: 4rem;
    margin-top: -6rem;
  }

  .social-media-wrap {
    flex-direction: column;
  }
  .social-icons{
    transform: translateX(40%);
  }
  .social-logo{
    font-size: 3rem;
  }
}
/* @media screen and (min-device-width: 1024px) and (max-device-width: 1439px){



}
@media screen and (min-device-width: 1440px) and (max-device-width: 1920px){


  
} */