*{
    margin: 0;
    padding: 0;
}
.odr{
    background-color: #ffeb98;
    padding-top:20%;
}
.odrin{
    display: flex;
    flex-direction: column;
    border: 1px solid red;
    margin-left: 40%;
    border-radius: 10px;
}
.odrins{
    border: 1px solid red;
    margin-left: 6px;
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 10px;
}
.odrinn{
    display: flex;
    flex-direction:column;
    margin-top: 40%;
    margin-bottom: 40%;
    margin-left: 40%;
    width: 40%;
}
.zoo{
    width: 40%;
    height: 4%;
    margin-left: 72%;
    margin-top: -35%;
}
.soo{
    width: 60%;
    margin-left: 72%;
    margin-top: -35%;
    margin-bottom: 10%;
}
.ob{
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px; 
}
.ob:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.form-check1{
    margin-bottom: 15%;
}

@media  only screen and (min-device-width: 0px) and (min-device-height: 0px) and (max-device-width: 320px) and (max-device-height: 790px) {
    .odrin{
        width: 80%;
        margin-left: 20%;
        transform: translate(-15%, -10%);  
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .odrinn{
        transform: translate(-40%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
    transform: translateX(40%);
    }
    .ob{
        transform: translate(10%,20%);
    }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
    .odrin{
        width: 80%;
        margin-left: 20%;
        transform: translate(-15%, -10%);  
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .odrinn{
        transform: translate(-40%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
    transform: translateX(40%);
    }
    .ob{
        transform: translate(10%,20%);
    }

}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
    .odrin{
        width: 80%;
        margin-left: 20%;
        transform: translate(-15%, -10%);  
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
    transform: translateX(40%);
    }
    .ob{
        transform: translate(20%,20%);
    }
    

}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
    .odrin{
        width: 80%;
        margin-left: 20%;
        transform: translate(-12%, -10%);  
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
    transform: translateX(40%);
    }
    .ob{
        transform: translate(20%,20%);
    }
    

}
@media  only screen and (min-device-width: 360px) and (min-device-height: 801px) and (min-device-width: 360px) and (min-device-height: 840px){
    .odrin{
        width: 80%;
        margin-left: 20%;
        transform: translate(-12%, -10%);  
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
    transform: translateX(40%);
    }
    .ob{
        transform: translate(20%,20%);
    }
    

}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
    .odrin{
        width: 80%;
        margin-left: 20%;
        transform: translate(-12%, -10%);  
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
    transform: translateX(40%);
    }
    .ob{
        transform: translate(20%,20%);
    }
    
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
    .odrin{
        width: 80%;
        margin-left: 20%;
        transform: translate(-12%, -10%);  
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
    transform: translateX(40%);
    }
    .ob{
        transform: translate(20%,20%);
    }
    

}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
    .odrin{
        width: 80%;
        margin-left: 20%;
        transform: translate(-12%, -10%);  
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
    transform: translateX(40%);
    }
    .ob{
        transform: translate(20%,20%);
    }
    
}
@media  only screen and (min-device-width: 385px) and (min-device-height:801px) and (max-device-width: 385px) and (max-device-height: 999px){
    .odrin{
        width: 80%;
        margin-left: 20%;
        transform: translate(-12%, -10%);  
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
    transform: translateX(40%);
    }
    .ob{
        transform: translate(20%,20%);
    }
    
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,10%);
    }
    .ob{
        transform: translate(20%,30%);
    }
    .form-check2{
        margin-bottom: 5%;
    }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,10%);
    }
    .ob{
        transform: translate(20%,30%);
    }
    .form-check2{
        margin-bottom: 5%;
    }
    
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,10%);
    }
    .ob{
        transform: translate(20%,30%);
    }
    .form-check2{
        margin-bottom: 5%;
    }
    
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,10%);
    }
    .ob{
        transform: translate(20%,30%);
    }
    .form-check2{
        margin-bottom: 5%;
    }
    
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,10%);
    }
    .ob{
        transform: translate(20%,30%);
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,10%);
    }
    .ob{
        transform: translate(20%,30%);
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,10%);
    }
    .ob{
        transform: translate(20%,30%);
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,10%);
    }
    .ob{
        transform: translate(20%,30%);
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,10%);
    }
    .ob{
        transform: translate(20%,30%);
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,15%);
    }
    .ob{
        transform: translate(20%,60%)
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 9px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
    }
    .soo{
        transform: translateY(35%);
    }
    .zoo{
        transform: translate(40%,15%);
    }
    .ob{
        transform: translate(20%,60%)
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
     
     /*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 16px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translateY(60%);
    }
    .zoo{
        transform: translate(40%,25%);
        width: 30%;
    }
    .ob{
        transform: translate(20%,60%)
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 16px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translateY(75%);
    }
    .zoo{
        transform: translate(40%,30%);
        width: 30%;
    }
    .ob{
        transform: translate(20%,60%)
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 16px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translateY(75%);
    }
    .zoo{
        transform: translate(40%,30%);
        width: 30%;
    }
    .ob{
        transform: translate(20%,60%)
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 16px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translateY(75%);
    }
    .zoo{
        transform: translate(40%,30%);
        width: 30%;
    }
    .ob{
        transform: translate(20%,60%)
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 16px;
    }
    .odrinn{
        transform: translate(-50%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translateY(75%);
    }
    .zoo{
        transform: translate(40%,30%);
        width: 30%;
    }
    .ob{
        transform: translate(20%,60%)
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -10%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 22px;
    }
    .odrinn{
        transform: translate(-20%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-60%,120%);
        width: 45%;
    }
    .zoo{
        transform: translate(-70%,50%);
        width: 20%;
    }
    .ob{
        transform: translate(35%,70%);
        width: 50%;
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
    .odrin{
        width: 90%;
        margin-left: 20%;
        transform: translate(-15%, -15%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 22px;
    }
    .odrinn{
        transform: translate(-20%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-60%,120%);
        width: 45%;
    }
    .zoo{
        transform: translate(-70%,50%);
        width: 20%;
    }
    .ob{
        transform: translate(35%,70%);
        width: 50%;
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
    .odrin{    
    width: 40%;
    margin-left: 20%;
    transform: translate(30%, -25%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 12px;
    }
    .odrinn{
        transform: translate(-30%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-25%,85%);
        width: 45%;
    }
    .zoo{   
    transform: translate(0%,18%);
    width: 30%;
    }
    .ob{
        transform: translate(55%,70%);
        width: 50%;
    }
    .form-check2{
        margin-bottom: 5%;
    }

}
     
     
     /*desktops*/
@media only screen and (min-device-width: 1280px) and (min-device-height: 901px) and (max-device-width: 1280px) and (max-device-height: 1024px){
    .odr{
        margin-bottom: -10%;
    }
    .odrin{    
        width: 40%;
        margin-left: 20%;
        transform: translate(30%, -33%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15px;
    }
    .odrinn{
        transform: translate(-30%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-25%,85%);
        width: 45%;
    }
    .zoo{   
        transform: translate(0%,18%);
        width: 30%;
    }
    .ob{
        transform: translate(55%,70%);
        width: 50%;
    }
    .form-check2{
     margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
    .odr{
        margin-bottom: -10%;
    }
    .odrin{    
        width: 40%;
        margin-left: 20%;
        transform: translate(30%, -33%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15px;
    }
    .odrinn{
        transform: translate(-30%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-25%,85%);
        width: 45%;
    }
    .zoo{   
        transform: translate(0%,18%);
        width: 30%;
    }
    .ob{
        transform: translate(55%,70%);
        width: 50%;
    }
    .form-check2{
     margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
    .odr{
        margin-bottom: -10%;
    }
    .odrin{    
        width: 40%;
        margin-left: 20%;
        transform: translate(30%, -33%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15px;
    }
    .odrinn{
        transform: translate(-30%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-25%,85%);
        width: 45%;
    }
    .zoo{   
        transform: translate(0%,18%);
        width: 30%;
    }
    .ob{
        transform: translate(55%,70%);
        width: 50%;
    }
    .form-check2{
     margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height: 1024px){
    .odr{
        margin-bottom: -10%;
    }
    .odrin{    
        width: 40%;
        margin-left: 20%;
        transform: translate(30%, -33%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15px;
    }
    .odrinn{
        transform: translate(-30%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-25%,85%);
        width: 45%;
    }
    .zoo{   
        transform: translate(0%,18%);
        width: 30%;
    }
    .ob{
        transform: translate(55%,70%);
        width: 50%;
    }
    .form-check2{
     margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height: 1050px){
    .odr{
        margin-bottom: -10%;
    }
    .odrin{    
        width: 40%;
        margin-left: 20%;
        transform: translate(30%, -33%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15px;
    }
    .odrinn{
        transform: translate(-30%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-25%,85%);
        width: 45%;
    }
    .zoo{   
        transform: translate(0%,18%);
        width: 30%;
    }
    .ob{
        transform: translate(55%,70%);
        width: 50%;
    }
    .form-check2{
     margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px) and (max-device-height: 1080px){
    .odr{
        margin-bottom: -10%;
    }
    .odrin{    
        width: 40%;
        margin-left: 20%;
        transform: translate(30%, -33%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15px;
    }
    .odrinn{
        transform: translate(-30%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-25%,85%);
        width: 45%;
    }
    .zoo{   
        transform: translate(0%,18%);
        width: 30%;
    }
    .ob{
        transform: translate(55%,70%);
        width: 50%;
    }
    .form-check2{
     margin-bottom: 5%;
    }

}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px) and (max-device-height: 1440px){
    .odr{
        margin-bottom: -10%;
    }
    .odrin{    
        width: 40%;
        margin-left: 20%;
        transform: translate(30%, -33%);
    }
    .odrins{
        width: 95%;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15px;
    }
    .odrinn{
        transform: translate(-30%, 0%);
        width: 60%;
        font-size: 145%;
    }
    .soo{
        transform: translate(-25%,85%);
        width: 45%;
    }
    .zoo{   
        transform: translate(0%,40%);
        width: 25%;
    }
    .ob{
        transform: translate(55%,70%);
        width: 50%;
    }
    .form-check2{
     margin-bottom: 5%;
    }
}