* {
  /* box-sizing: border-box; */
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

html
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}
@media
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5){

  html,
  body{
    width:100%;
    overflow-x:hidden;
  }

}