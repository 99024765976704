#products{
    padding-top: 0px;
    padding-bottom: 30px;
}

#products .contents{
    padding-bottom: 20px;
}

.headline{
    color:red;
    padding-bottom: 20px;
    padding-top: 20px;
}