* {
    margin: 0;
    padding: 0;    
}

.for{
    background-color: #ffeb98;
}
.forin{
    display: flex;
}

@media  only screen and (min-device-width: 0px) and (min-device-height: 569px) and (max-device-width: 320px) and (max-device-height: 790px) {
 .forin{
    transform: translateX(0%);
 }        
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
    .forin{
        transform: translateX(0%);
     }   
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 840px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 385px) and (min-device-height:801px) and (max-device-width: 385px) and (max-device-height: 999px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
    .forin{
        transform: translateX(0%);
     }  
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
    .forin{
        transform: translateX(0%);
     }  
}
  
  /*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
    .forin{
        transform: translateX(10%);
     }  
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
    .forin{
        transform: translateX(12%);
     }     
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
    .forin{
        transform: translateX(12%);
     }  
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
    .forin{
        transform: translateX(12%);
     }  
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
    .forin{
        transform: translateX(12%);
     }  
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
    .forin{
        transform: translateX(12%);
     }  
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
    .forin{
        transform: translateX(25%);
     }  
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
    .forin{
        transform: translateX(25%);
     }  
}
  
  
  /*desktops*/
@media only screen and (min-device-width: 1280px) and (min-device-height: 901px) and (max-device-width: 1280px) and (max-device-height: 1024px){
    .forin{
        transform: translateX(25%);
     }  
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
    .forin{
        transform: translateX(25%);
     }  
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
    .forin{
        transform: translateX(30%);
     }  
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height: 1024px){
    .forin{
        transform: translateX(30%);
     }  
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height: 1050px){
    .forin{
        transform: translateX(30%);
     }  
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px) and (max-device-height: 1080px){
    .forin{
        transform: translateX(30%);
     }  
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
    .forin{
        transform: translateX(40%);
     }  
}