:root {
    --primary: #fff;
  }
  
  .OBBI {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .OBBI--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }
  
  .OBBI--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .OBBI--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .OBBI--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .OBBI--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }
  
  .OBBI--wide {
    padding: 12px 64px;
    font-size: 20px;
  }
  
  .OBBI--large:hover,
  .OBBI--medium:hover,
  .OBBI--mobile:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
  }
  
  .OBBI--wide:hover {
    color: #fff;
    background-color: #f00946;
    transition: all 0.2s ease-out;
  }
  
  .OBBI-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
  }
  
  .blue {
    background-color: #276afb;
    color: #fff;
    border: none;
  }
  
  .red {
    background-color: #f00946;
    color: #fff;
    border: none;
  }
  
  .primary {
    background-color: #242424;
    color: #fff;
    border: none;
  }
  
  .primary:hover {
    background-color: #fff;
    color: #242424;
    border: none;
  }
  
  .green {
    background: #25ce4a;
    color: #fff;
    border: none;
  }
  
  .green:hover {
    background-color: #242424;
  }
  
@media  only screen and (min-device-width: 0px) and (min-device-height: 0px) and (max-device-width: 320px) and (max-device-height: 568px) {
  .OBBI--wide{
    transform: translate(-25%, -50%);
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .OBBI--wide{
    transform: translate(-20%,-80%);
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .OBBI--wide{
    transform: translate(-20%,-80%);
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .OBBI--wide{
    transform: translate(-20%,-20%);
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 840px){
  .OBBI--wide{
    transform: translate(-20%,-20%);
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .OBBI--wide{
    transform: translate(-20%,-20%);
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .OBBI--wide{
    transform: translate(-20%,-20%);
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .OBBI--wide{
    transform: translate(-20%,-20%);
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:801px) and (max-device-width: 385px) and (max-device-height: 999px){
  .OBBI--wide{
    transform: translate(-20%,-20%);
  }
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
  .OBBI--wide{
    transform: translate(-20%,-20%);
  }
}
@media  only screen and (min-device-width: 391px) and (min-device-height: 723px) and (max-device-width: 393px) and (max-device-height: 851px){
  .OBBI--wide{
    transform: translate(-20%,-20%);
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .OBBI--wide{
    transform: translate(-15%,-20%);
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .OBBI--wide{
    transform: translate(-15%,-20%);
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .OBBI--wide{
    transform: translate(-15%,-20%);
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .OBBI--wide{
    transform: translate(-15%,-20%);
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .OBBI--wide{
    transform: translate(-15%,-20%);
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .OBBI--wide{
    transform: translate(-15%,-20%);
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .OBBI--wide{
    transform: translate(-15%,-20%);
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .OBBI--wide{
    transform: translate(-5%,-20%);
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .OBBI--wide{
    transform: translate(-5%,-20%);
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .OBBI--wide{
    transform: translate(20%, -10%);
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .OBBI--wide{
    transform: translate(20%, -10%);
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .OBBI--wide{
    transform: translate(20%, -10%);
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .OBBI--wide{
    transform: translate(20%, -10%);
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .OBBI--wide{
    transform: translate(20%, -10%);
  }
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .OBBI--wide{
    transform: translate(40%, -10%)
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .OBBI--wide{
    transform: translate(50%, -20%);
  }
}
@media only screen and (min-device-width: 1153px) and (min-device-height: 730px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
  .OBBI--wide{
    transform: translate(70%, -20%);
  }
}

/*desktops*/
@media only screen and (min-device-width: 1154px) and (min-device-height: 901px) and (max-device-width: 1280px) and (max-device-height: 1024px){
  .OBBI--wide{
    transform: translate(40%, -20%);
  }
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
  .OBBI--wide{
    transform: translate(40%, -20%);
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
  .OBBI--wide{
    transform: translate(40%, -20%);
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1680px) and (max-device-height: 1050px){
  .OBBI--wide{
    transform: translate(40%, -20%);
}
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px) and (max-device-height: 1080px){
  .OBBI--wide{
    transform: translate(40%, -20%);
}
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  .OBBI--wide{
    transform: translate(40%, -35%);
  }
}
