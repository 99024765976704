* {
  margin: 0;
  padding: 0;
}

.home__hero-sectiona {
  color: #fff;
  padding: 160px 0;
}

.home__hero-rowaaa {
  align-items: center;
  
}

.rowaa {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: row  ;
  flex-direction: column;
  align-content: stretch;
}

.cola {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapperaa {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-linea {
  color: #fff603;
  font-size: 25px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.headingaa {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff603;
  font-size: 30px;
  transform: translateX(30%);
}

.dark {
  color: #00642f;
}

.newBg{
  background-color: #ffeb98;
}

.darkBg {
  background-color: #00642f;
}

.home__hero-subtitleaa {
  margin-bottom: 35px;
  font-size: 18px;
  /* line-height: 24px; */
}



@media  only screen and (min-device-width: 0px) and (min-device-height: 569px) and (max-device-width: 320px) and (max-device-height: 790px) {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translateX(5%);
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -105%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-24%);
    width: 200%;
    text-align: justify;
    font-size: 13px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(60%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(15%,40%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-25%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(60%,-20%);
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-22%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:801px) and (max-device-width: 385px) and (max-device-height: 999px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-23%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(23%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-24%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-24%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-25%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-25%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-25%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-25%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-25%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-26%);
    width: 200%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-25%);
    width: 206%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(55%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 1153px) and (min-device-height: 721px) and (max-device-width: 1280px) and (max-device-height: 900px)  {
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-26.5%);
    width: 206%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(70%,-20%);
    /* text-align: center; */
  }
}


/*desktops*/
@media only screen and (min-device-width: 1153px) and (min-device-height: 901px) and (max-device-width: 1280px) and (max-device-height:1024px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-26.5%);
    width: 206%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(70%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-26.5%);
    width: 206%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(70%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-26.5%);
    width: 206%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(70%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height:1024px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-26.5%);
    width: 206%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(90%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height: 1050px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-26.5%);
    width: 206%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(95%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px) and (max-device-height: 1080px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-26.5%);
    width: 206%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(135%,-20%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  .rowaa{
    flex-flow: column-reverse;
  }
  .top-linea{
    margin-top: 15%;
    transform: translate(35%,45%);
    font-size: 20px;
  }
  .headingaa{
    font-size: 35px;
    text-align: center;
    margin-left: -100%;
  }
  .home__hero-subtitleaa{
    transform: translateX(-26.5%);
    width: 206%;
    text-align: justify;
    font-size: 14px;
  }
  .home__hero-text-wrapperaa{
    transform: translate(200%,-20%);
    /* text-align: center; */
  }
}
