* {
  margin: 0;
  padding: 0;
}

.home__hero-sectionp {
  color: #fff;
  padding: 160px 0;
}

.home__hero-rowpi {
  align-items: center;
}

.rowpi {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: row  ;
  align-content: stretch;
}

.colpi {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapperpi {
  padding-top: 0;
  padding-bottom: 60px;
  margin-left: 15%;
}

.headinghpi {
  color: #fff603;
}

.headingPi.dark {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  transform: translate(-15%,-20%);
}

.dark {
  color: #00642f;
}

.newBg{
  background-color: #ffeb98;
}

.darkBg {
  background-color: #00642f;
}

.home__hero-subtitlepi {
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

/* .home__hero-img-wrapperpi {
} */

.home__hero-imgpi {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

/* img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
} */

@media  only screen and (min-device-width: 0px) and (min-device-height: 0px) and (max-device-width: 320px) and (max-device-height: 790px) {
  .rowpi{
    flex-flow: column-reverse;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-15%, -50%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 224%;
    text-align: justify;
    font-size: 85%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-25%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 145%;
    transform: translate(-20%, 470%);
  }
}
@media  only screen and (min-width: 321px) and (max-device-width: 360px) and (max-device-height: 640px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-5%, -40%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 230%;
    text-align: justify;
    font-size: 85%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 321px) and (min-device-height: 641px) and (max-device-width: 360px) and (max-device-height: 740px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-7%, -40%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 230%;
    text-align: justify;
    font-size: 85%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 741px) and (max-device-width: 360px) and (max-device-height: 800px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-7%, -40%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 360px) and (min-device-height: 840px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-7%, -40%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 361px) and (max-device-width: 375px) and (max-device-height: 677px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-7%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-21%,0%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 361px) and (min-device-height: 678px) and (max-device-width: 375px) and (max-device-height: 812px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-7%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-21%,0%);
    width: 230%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 376px) and (min-device-height:700px) and (max-device-width: 385px) and (max-device-height: 800px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-7%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 385px) and (min-device-height:801px) and (max-device-width: 385px) and (max-device-height: 999px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-7%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 386px) and (max-device-width: 390px) and (max-device-height: 722px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-7%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 391px) and (max-device-width: 393px) and (max-device-height: 851px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-18%, -45%)
  }
}
@media  only screen and (min-device-width: 394px) and (max-device-width: 411px) and (max-device-height: 731px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 394px) and (min-device-height: 732px) and (max-device-width: 411px) and (max-device-height: 823px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 732px) and (max-device-width: 412px) and (max-device-height: 732px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 412px) and (min-device-height: 733px) and (max-device-width: 412px) and (max-device-height: 999px) {
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
  .home__hero-imgpi{
    width: 125%;
    transform: translate(-15%, -45%)
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height:736px) and (max-device-width: 414px) and (max-device-height: 736px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(0%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpi{
    width: 160%;
    transform: translate(-20%, -65%);
    margin-bottom: -140%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 413px) and (min-device-height: 737px) and (max-device-width: 414px) and (max-device-height: 896px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 100%;
  }
  .home__hero-imgpi{
    width: 160%;
    transform: translate(-20%, -65%);
    margin-bottom: -140%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 415px) and (max-device-width: 428px) and (max-device-height: 926px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(-5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 94%;
  }
  .home__hero-imgpi{
    width: 160%;
    transform: translate(-20%, -65%);
    margin-bottom: -140%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 429px) and (max-device-width: 480px) and (max-device-height: 853px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-20%,0%);
    width: 220%;
    text-align: justify;
    font-size: 94%;
  }
  .home__hero-imgpi{
    width: 160%;
    transform: translate(-20%, -25%);
    margin-bottom: -70%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-25%,-20%);
    /* text-align: center; */
  }
}
@media  only screen and (min-device-width: 481px) and (max-device-width: 540px) and (max-device-height: 720px){
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 210%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-25%,0%);
    width: 220%;
    text-align: justify;
    font-size: 94%;
  }
  .home__hero-imgpi{
    width: 160%;
    transform: translate(-20%, -25%);
    margin-bottom: -70%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}

/*Tablets*/
@media only screen and (min-device-width: 541px) and (max-device-width: 768px) and (max-device-height: 1024px)  {
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 290%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-25%,0%);
    width: 220%;
    text-align: justify;
    font-size: 110%;
  }
  .home__hero-imgpi{
    width: 140%;
    transform: translate(-20%, -20%);
    margin-bottom: -50%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 769px) and (min-device-height: 1025px) and (max-device-width: 810px) and (max-device-height: 1080px)  {
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 290%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-25%,0%);
    width: 220%;
    text-align: justify;
    font-size: 110%;
  }
  .home__hero-imgpi{
    width: 140%;
    transform: translate(-20%, -20%);
    margin-bottom: -50%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 811px) and (min-device-height: 1081px) and (max-device-width: 820px) and (max-device-height: 1180px)  {
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 290%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-25%,0%);
    width: 220%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpi{
    width: 140%;
    transform: translate(-20%, -20%);
    margin-bottom: -50%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 821px) and (max-device-width: 834px) and (max-device-height: 1112px)  {
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 290%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-25%,0%);
    width: 220%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpi{
    width: 140%;
    transform: translate(-20%, -20%);
    margin-bottom: -50%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 833px) and (min-device-height: 1113px) and (max-device-width: 834px) and (max-device-height: 1194px)  {
  .rowpi{
    flex-flow: column;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 290%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(5%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-25%,0%);
    width: 220%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpi{
    width: 140%;
    transform: translate(-20%, -20%);
    margin-bottom: -50%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(-15%,-25%);
    /* text-align: center; */
  }
}
@media only screen and (min-device-width: 834px) and (min-device-height: 1195px) and (max-device-width: 1024px) and (max-device-height: 1366px)  {
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(10%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-5%,0%);
    width: 140%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpi{
    width: 115%;
    transform: translate(-20%, 80%);
    margin-bottom: 45%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1152px) and (max-device-height: 720px)  {
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(10%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-5%,0%);
    width: 160%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpi{
    width: 100%;
    transform: translate(-10%, 90%);
    margin-bottom: 45%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);
  }
}
@media only screen and (min-device-width: 1153px) and (max-device-width: 1280px) and (max-device-height: 900px){
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(10%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translate(-5%,0%);
    width: 160%;
    text-align: justify;
    font-size: 125%;
  }
  .home__hero-imgpi{
    width: 100%;
    transform: translate(-10%, 90%);
    margin-bottom: 45%;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);
  }
}


/*desktops*/
@media only screen and (min-device-width: 1280px) and (min-device-height: 901px) and (max-device-width: 1280px) and (max-device-height: 1024px){
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(0%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translateX(-11%);
    width: 160%;
    text-align: justify;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);

  }
  .home__hero-imgpi{
    width: 70%;
    transform: translate(20%,50%);
  }
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (max-device-height: 768px){
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(0%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translateX(-11%);
    width: 160%;
    text-align: justify;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);

  }
  .home__hero-imgpi{
    width: 70%;
    transform: translate(20%,65%);
    margin-bottom: 20%;
  }
}
@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) and (max-device-height: 1050px){
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(0%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translateX(-11%);
    width: 160%;
    text-align: justify;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);

  }
  .home__hero-imgpi{
    width: 70%;
    transform: translate(20%,65%);
    margin-bottom: 20%;
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) and (max-device-height: 1024px){
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(0%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translateX(-11%);
    width: 160%;
    text-align: justify;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);

  }
  .home__hero-imgpi{
    width: 70%;
    transform: translate(20%,65%);
    margin-bottom: 20%;
  }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1680px) and (max-device-height: 1050px){
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(0%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translateX(-11%);
    width: 160%;
    text-align: justify;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);

  }
  .home__hero-imgpi{
    width: 70%;
    transform: translate(20%,65%);
    margin-bottom: 20%;
  }
}
@media only screen and (min-device-width: 1681px) and (max-device-width: 1920px) and (max-device-height: 1080px){
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(0%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translateX(-11%);
    width: 160%;
    text-align: justify;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);

  }
  .home__hero-imgpi{
    width: 70%;
    transform: translate(20%,65%);
    margin-bottom: 20%;
  }
}
@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px){
  .rowpi{
    flex-flow: row;
  }
  .headinghpi {
    
    margin-bottom: 24px;
    font-size: 330%;
    line-height: 1.1;
    font-weight: 600;
    width: 185%;
    transform: translate(0%, -20%);
  }
  .home__hero-subtitlepi{
    transform: translateX(-11%);
    width: 160%;
    text-align: justify;
  }
  .home__hero-text-wrapperpi{
    transform: translate(5%,-15%);

  }
  .home__hero-imgpi{
    width: 70%;
    transform: translate(20%,65%);
    margin-bottom: 20%;
  }
}
